@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Poppins:wght@400;500;600;700&family=Roboto:wght@300;400;500;700&family=Work+Sans:wght@400;500;600&display=swap");
@font-face {
  font-family: 'montblack';
  src: url('../fonts/mont-black-webfont.woff2') format('woff2'),
       url('../fonts/mont-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'montbold';
  src: url('../fonts/mont-bold-webfont.woff2') format('woff2'),
       url('../fonts/mont-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'montbook';
  src: url('../fonts/mont-book-webfont.woff2') format('woff2'),
       url('../fonts/mont-book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'monthairline';
  src: url('../fonts/mont-hairline-webfont.woff2') format('woff2'),
       url('../fonts/mont-hairline-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'montheavy';
  src: url('../fonts/mont-heavy-webfont.woff2') format('woff2'),
       url('../fonts/mont-heavy-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'montlight';
  src: url('../fonts/mont-light-webfont.woff2') format('woff2'),
       url('../fonts/mont-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'montregular';
  src: url('../fonts/mont-regular-webfont.woff2') format('woff2'),
       url('../fonts/mont-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'montsemibold';
  src: url('../fonts/mont-semibold-webfont.woff2') format('woff2'),
       url('../fonts/mont-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
html {
  font-size: 16px;
}
body {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #000;
  background-color: #fff;
}
p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.18;
  color: #515151;
  margin-bottom: 16px;
}
p:last-child {
  margin-bottom: 0;
}
a {
  color: #1976d2;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}
::placeholder {
  font-weight: 400 !important;
  color: #000 !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 50px;
}
.mt-16,
.mx-16 {
  margin-top: 16px;
}
.mb-16,
.mx-16 {
  margin-bottom: 16px;
}
.mt-20,
.mx-20 {
  margin-top: 20px;
}
.mb-20,
.mx-20 {
  margin-bottom: 20px;
}
.mt-24,
.mx-24 {
  margin-top: 24px;
}
.mb-24,
.mx-24 {
  margin-bottom: 24px;
}
.mb-34,
.mx-34 {
  margin-bottom: 34px;
}
.btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.18;
  text-align: center;
  color: #fff;
  padding: 15px 24px 12px;
  border-radius: 50px;
  display: inline-block;
  border-width: 0 0 3px 0;
  border-style: solid;
  box-shadow: none !important;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.btn-pink {
  background-color: #091747;
  border-color: #848ba3;
}
.btn-pink:hover {
  color: #fff;
  background-color: #374B80;
  border-color: #00000041;
}
.btn-parrot {
  background-color: #091747;
  border-color: #848ba3;
}
.btn-parrot:hover {
  color: #fff;
  background-color: #374B80;
  border-color: #00000041;
}
.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #1976d2;
  border-color: #012b69;
}
.btn-primary:hover {
  color: #fff;
  background-color: #1162b3;
  border-color: #001a41;
}
.btn-outline-secondary {
  border-width: 1px 1px 3px 1px;
  border-style: solid;
  border-color: #e8e8e8;
  color: #4b5360;
  background: #fff;
}
.btn-outline-secondary:hover {
  color: #4b5360;
  background-color: #fafafa;
  border-color: #dfdfdf;
}
.btn-outline-primary {
  border-width: 1px 1px 3px 1px;
  border-style: solid;
  border-color: #e8e8e8;
  color: #091747;
  background: #fff;
}
.btn-outline-primary:hover {
  background-color: #091747;
  border-color: #848ba3;
}
.btn-outline-grey {
  border-width: 1px 1px 3px 1px;
  border-style: solid;
  border-color: #e8e8e8;
  border-radius: 50px;
  font-weight: 600;
  color: #212121;
  padding: 8px 36px 6px 16px;
  background-image: url(../img/svg/chevron-right.svg);
  background-position: right 17px center;
  background-size: 7px;
  background-repeat: no-repeat;
  transition: all 0.4s ease-in-out;
}
.btn-outline-grey:hover {
  background-color: #fafafa;
}
.btn-sm {
  font-weight: 400;
  font-size: 14px;
  border-width: 1px 1px 2px 1px;
  padding: 6px 12px 4px 12px;
}
.login-section {
  height: 100vh;
}
.login-section.with-bg {
  background-image: url(../img/register-banner.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  height: unset;
}
.login-section.with-bg .login-upper-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.login-upper-section {
  height: 93%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
.login-upper-section > div,
.login-upper-section > div > div {
  height: 100%;
}
.login-inner {
  box-shadow: 0px 24px 74px rgb(0 0 0 / 3%);
  border-radius: 24px;
  background-color: #fff;
  padding: 48px;
  max-width: 580px;
  margin-left: auto;
  margin-right: 60px;
}
.login-logo img {
  max-width: 80%;
  margin-bottom: 25px;
}
.login-title {
  display: block;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  color: #212121;
  margin-bottom: 24px;
}
.login-text {
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.18;
  color: #8e8e8e;
  margin-bottom: 35px;
}
.login-form-inner .form-group,
.login-form-inner .form-check {
  margin-bottom: 24px;
}
.login-form-inner .form-control {
  padding: 15px;
  color: #212121;
  background-color: transparent;
  border: 1px solid #dbdbdb;
  border-radius: 50px;
  line-height: 1.18;
  font-size: 14px;
  font-weight: 700;
  box-shadow: none !important;
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 16px;
  resize: none;
}
.login-form-inner .form-control.email,
.login-form-inner .form-control.phone,
.login-form-inner .form-control.password {
  padding: 15px 16px 15px 42px;
}
.login-form-inner .form-control.email {
  background-image: url(../img/svg/mail.svg);
}
.login-form-inner .form-control.phone {
  background-image: url(../img/svg/phone-line.svg);background-size: 12px;
}
.login-form-inner .form-control.password{
  background-image: url(../img/svg/password.svg);
}
.login-form-inner textarea.form-control {
  border-radius: 15px;
}
.login-form-inner .form-label {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
.login-form-inner .form-label span {
  color: #091747;
}
.login-form-trouble {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.1;
}
.login-image .dashboard-card {
  min-width: 262px;
  position: absolute;
}
.login-image .dashboard-card.is-total-appraisals {
  top: -76px;
  left: 295px;
  box-shadow: -30px 62px 101px rgba(0, 0, 0, 0.12);
  z-index: 2;
  animation-name: float-bob;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.login-image .dashboard-card.is-total-won {
  top: 65px;
  left: 180px;
  border: none;
  box-shadow: 0px 32px 81px rgba(0, 0, 0, 0.08);
  z-index: 1;
  animation-name: float-bob-up;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotateme{0%{transform:rotate(0deg)}to{transform:rotate(-1turn)}}@keyframes float-bob{0%{transform:translateY(0)}50%{transform:translateY(-15px)}to{transform:translateY(0)}}@keyframes float-bob-up{0%{transform:translateY(-15px)}50%{transform:translateY(0)}to{transform:translateY(-15px)}}


.form-group-with-password {
  position: relative;
}
.form-group-with-password .icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
}
.login-form-inner .btn {
  width: 100%;
  display: block;
  margin-bottom: 32px;
}
.login-form-register-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.18;
  padding: 15px;
  color: #000;
  background-color: transparent;
  border: 1px solid #dbdbdb;
  border-radius: 50px;
}
.login-form-register-link a {
  color: inherit;
  text-decoration-line: underline;
  font-weight: 700;
  margin-left: 5px;
  transition: all 0.4s ease-in-out;
}
.login-form-register-link a:hover {
  color: #1976d2;
}
.login-form-login-link {
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.18;
  color: #000;
  background-color: transparent;
  border-radius: 50px;
}
.login-form-login-link a {
  color: inherit;
  text-decoration-line: underline;
  font-weight: 700;
  margin-left: 5px;
  transition: all 0.4s ease-in-out;
}
.login-form-login-link a:hover {
  color: #091747;
}
.login-form-login-link i {
  font-size: 24px;
  margin-right: 5px;
}
.login-copyright {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-copyright span {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #8c8c8c;
  display: inline-block;
}
.login-copyright span a{color: #8c8c8c;transition: all 0.4s ease-in-out;}
.login-copyright span a:hover{color: #091747;}
.login-copyright span:first-child {
  margin-right: 48px;
}
.login-image {
  position: relative;
}
.dashboard-card {
  background-color: #fff;
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  border-color: #ededed;
  border-radius: 16px;
  padding: 18px;
  position: relative;
}
.dashboard-card-row {
  display: flex;
  flex-wrap: wrap;
}
.dashboard-card-icon {
  flex: 0 0 50px;
  max-width: 50px;
  height: 50px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(245, 245, 245, 0) 0%,
    rgba(245, 245, 245, 0.55) 100%
  );
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 28px;
}
.dashboard-card-text {
  flex: 1;
  padding-left: 8px;
}
.dashboard-card-count {
  font-weight: 800;
  font-size: 32px;
  line-height: 1.16;
  color: #4b5360;
  display: block;
  margin-bottom: 8px;
}
.dashboard-card-label {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.1;
  color: #4b5360;
  display: block;
  margin-bottom: 8px;
}
.dashboard-card .progress {
  height: 4px;
  background-color: #f2f2f2;
}
.dashboard-card-icon .icon{
  color: #6690ff;
}
/* 
.is-total-appraisals .dashboard-card-icon {
  color: #6690ff;
}
.is-today-appraisals .dashboard-card-icon {
  color: #eba142;
}
.is-working .dashboard-card-icon {
  color: #ba68c8;
}
.is-appraised .dashboard-card-icon {
  color: #5767be;
}
.is-offermade .dashboard-card-icon {
  color: #e8726c;
}
.is-counteroffer .dashboard-card-icon {
  color: #eb3c77;
}
.is-accepted-purchase .dashboard-card-icon {
  color: #8bc34a;
}
.is-no-deal .dashboard-card-icon {
  color: #f44646;
}
.is-earnings .dashboard-card-icon {
  color: #1ebbd3;
}
.is-most-accepted .dashboard-card-icon {
  color: #19ad28;
} */
.login-image .total-appraisals-added-card {
  position: absolute;
  top: -76px;
  right: 22px;
  background-color: #fff;
  box-shadow: 0px 32px 41px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 30px 24px;
  width: 277px;
  animation-name: float-bob-up;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.login-image .total-appraisals-count{font-size: 45px;}
.login-image .total-appraisals-text{font-size: 24px;}
.login-image .total-appraisals-added-card p{font-size: 14px;}
.login-image .dashboard-card{padding: 24px;}
.dashboard-featured .total-appraisals-button-wrap {margin-top: 35px;}
.total-appraisals-added-card {padding: 32px;}


.total-appraisals-count {
  font-weight: 700;
  font-size: 60px;
  line-height: 1.27;
  color: #60b573;
  margin-bottom: 5px;
  display: block;
}
.total-appraisals-text {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  color: #212121;
}
.total-appraisals-added-card p {
  font-weight: 400;
  font-size: 21px;
  color: #3c3c3c;
}
.total-appraisals-button-wrap {
  margin-top: 35px;
  text-align: right;
}
.total-appraisals-button-wrap .btn{border-width: 0 0 4px 0;}
.total-appraisals-text img {
  max-width: 60px;
  margin-right: 8px;
}
.register-hero-text {
  background: linear-gradient(
    127.82deg,
    rgba(247, 247, 247, 0.9) -2.8%,
    rgba(247, 253, 255, 0.54) 74%,
    rgba(247, 247, 247, 0.9) 71.85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-weight: 800;
  font-size: 96px;
  line-height: 1;
  max-width: 714px;
  margin: auto;
}
.reset-password-section {
  background-image: url(../img/line-shape.svg);
  background-position: bottom center;
  background-size: 100%;
  background-repeat: no-repeat;
}
.login-tabs ul {
  background-color: #f5f5f5;
  border-radius: 60px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 5px;
  margin-bottom: 24px;
}
.login-tabs li {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #8C9199;
  border-radius: 50px;
  padding: 10px;
  flex: 0 0 50%;
  max-width: 50%;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.4s ease-in-out;
}
.login-tabs li i {
  margin-right: 10px;
}
.login-tabs li:hover,
.login-tabs li.active {
  background-color: #fff;
  color: #212121;
}
.form-privacy-policy {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25;
  color: #747b87;
  display: block;
  text-align: center;
}
.form-privacy-policy a {
  color: #0194ba;
}
.dashboard-navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 12px 33px;
  border-bottom: 1px solid #ededed;
}
.dashboard-navbar-logo a,
.header-logo a {
  display: inline-block;
  vertical-align: middle;
}
.dashboard-navbar-logo img {
  width: 260px;
}
.dashboard-navbar-search .form-control {
  border-radius: 50px;
  padding: 10px 15px 10px 42px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  background-color: #f7f7f7;
  background-image: url(../img/svg/search.svg);
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 16px;
  width: 34vw;
  border: none;
  box-shadow: none !important;
}
.dashboard-navbar-search .form-control::placeholder {
  color: #4b5360 !important;
}
.dashboard-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.dashboard-links li {
  display: inline-block;
  margin-right: 32px;
}
.dashboard-links li:last-child {
  margin-right: 0;
}
.dashboard-links li button {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #4b5360;
  outline: none;
  border: none;
  background-color: transparent;
  font-family: "Roboto", sans-serif;
}
.dashboard-links li button:hover {
  color: #091747;
}
.dashboard-button {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.dashboard-button-box {
  margin-right: 16px;
}
.dashboard-button-box:last-child {
  margin-right: 0;
}
.dashboard-button-box .btn-pink {
  font-weight: 500;
  font-size: 14px;
  padding: 11px 17px 8px;
}
.dashboard-button-box .btn-pink i {
  margin-right: 13px;
  vertical-align: -1px;
}
.dashboard-button-box .btn-icon {
  width: 40px;
  height: 40px;
  background-color: #fbfbfb;
  border: 1px solid #f9f9f9;
  color: #33577b;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon.is-new {
  position: relative;
}
.icon.is-new::after {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  background: #ff3d00;
  border-radius: 64px;
  top: 0;
  right: 0;
}
.dashboard-profile-dropdown {
  position: relative;
}
.dashboard-profile-dropdown-button {
  display: flex;
  align-items: center;
  background-color: #f0fcff;
  border-radius: 64px;
  color: #4b5360;
  padding: 6px 13px 6px 6px;
  border: none;
}
.dashboard-profile-dropdown-button img {
  margin-right: 8px;
  width: 33px;
  height: 33px;
  object-fit: cover;
  border-radius: 60px;
}
.dashboard-profile-dropdown span {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 12px;
  margin-right: 10px;
}
.dashboard-profile-dropdown i {
  font-size: 9px;
  color: #a6a6a6;
}
.dashboard-profile-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 9;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 10px 15px -10px #dfdfdf;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  opacity: 0;
  visibility: hidden;
  min-width: 100px;
}
.dashboard-profile-dropdown ul.show {
  opacity: 1;
  visibility: visible;
}
.dashboard-profile-dropdown li {
  display: block;
}
.dashboard-profile-dropdown li a {
  color: #4b5360;
  font-size: 14px;
  display: block;
  padding: 7px;
  border-top: 1px solid #e6e6e6;
}
.dashboard-profile-dropdown li:first-child a {
  border-top: 0;
}
.dashboard-profile-dropdown li a:hover {
  color: #091747;
}
.menuIcon {
  display: none;
  width: 32px;
  height: 32px;
  position: relative;
  padding: 0px;
  margin-right: 5px;
  color: #000;
}
.menuIcon span {
  display: block;
  width: 27px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: currentColor;
  margin: auto;
}
.menuIcon span:before {
  content: "";
  width: 21px;
  position: absolute;
  top: -8px;
  left: 0;
  height: 3px;
  background: currentColor;
}
.menuIcon span:after {
  content: "";
  width: 16px;
  position: absolute;
  bottom: -8px;
  left: 0;
  height: 3px;
  background: currentColor;
}
.menuIcon span,
.menuIcon span::after,
.menuIcon span::before {
  transition: all 0.4s ease-in-out;
}
.dashboard-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: calc(100vh - 70px);
}
.dashboard-inner {
  flex: 1;
  padding: 32px;
  background-color: #fbfbfb;
  overflow: auto;
  height: 100%;
  overflow-x: hidden;
}
.dashboard-sidebar {
  padding: 32px 0 0px 0;
  flex: 0 0 210px;
  max-width: 210px;
  border-right: 1px solid #ededed;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard-sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dashboard-sidebar li {
  display: block;
  margin-bottom: 8px;
}
.dashboard-sidebar li:last-child {
  margin-bottom: 0;
}
.dashboard-sidebar li a,
.dashboard-sidebar li button {
  padding: 12px 15px 12px 24px;
  display: flex;
  align-items: center;
  border-right: 2px solid transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #212121;
  border: none;
  outline: none;
  background-color: transparent;
}
.dashboard-sidebar li a i,
.dashboard-sidebar li button i {
  margin-right: 12px;
  color: #78818d;
  font-size: 18px;
}
.dashboard-sidebar li a:hover,
.dashboard-sidebar li button:hover {
  color: #091747;
}
.dashboard-sidebar li a.active {
  background: linear-gradient( 270deg, rgba(9,3,71,0.1) 0%, rgba(255, 255, 255, 0) 62.24% );
  border-color: #091747;
  font-weight: 700;
}
.dashboard-sidebar li a.active i {
  color: #091747;
}
.dashboard-sidebar hr {
  background-color: #b1b1b1;
  margin-left: 21px;
  margin-right: 21px;
}
.page-title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 1;
  color: #212121;
  margin: 0;
}
.page-sub-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  display: block;
  margin-top: 10px;
  color: #212121;
}
.dashboard-card-filter {
  margin-bottom: 10px;
}
.dashboard-card-select .form-select,
.dashboard-count-filter .form-select {
  padding: 12px 36px 12px 16px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #212121;
  box-shadow: none !important;
  background-image: url(../img/svg/chevron-down.svg);
  background-size: 14px;
  background-position: right 15px center;
  text-overflow: ellipsis;
}
.card-title {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  color: #212121;
}
.dashboard-card-select .form-select {
  background-color: #f7f7f7;
  border-color: #ededed;
}
.with-cursor .table tbody tr,
.vehicle-searclistbox {
  cursor: pointer;
}
.dashboard-card .table tbody td {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #212121;
  vertical-align: middle;
  padding: 10px 14px;
  white-space: nowrap;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-card .table thead th {
  padding: 18px 16px;
  color: #747b87;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  white-space: nowrap;
}
.dashboard-card .table img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50px;
}
.dashboard-card .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #efefef;
}
.dashboard-top-employees-profile,
.table-vehicle-image,
.table-user-image {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  position: relative;
}
.dashboard-card .table .total-won {
  color: #24953d;
}
.dashboard-card .table .total-amount {
  color: #ce8100;
}
.dashboard-top-employees {
  max-height: 170px;
  overflow: auto;
  height: 100%;
}
.dashboard-featured .dashboard-card {
  height: 100%;
}
.table {
  margin-bottom: 0;
}
.deal-user-image img {
  height: 59px;
  width: 59px;
  border-radius: 100px;
  object-fit: cover;
  margin-right: 16px;
}
.table-sort {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #09121f;
  display: inline-block;
  vertical-align: 2px;
  margin-left: 14px;
  cursor: pointer;
}
.table-sort.asc {
  border-bottom: 6px solid #09121f;
  border-top: none;
}
.dashboard-latest-apraisals .table tbody td {
  border-bottom: 1px solid #f7f7f7;
  padding-top: 8px;
  padding-bottom: 8px;
}
.dashboard-latest-apraisals .table tbody td a {
  color: #000;
}
.dashboard-latest-apraisals .table tbody td a:hover {
  color: #091747;
}
.dashboard-latest-apraisals .table tbody tr:last-child td {
  border-bottom: 0;
}
.dashboard-card .table .table-vehicle-image img {
  border-radius: 4px;
}
.dashboard-card .table .btn-icon {
  background-color: transparent;
  color: #747b87;
  font-size: 20px;
  margin-right: 18px;
  box-shadow: none;
  outline: none;
  border: none;
  padding: 0;
  transition: all 0.3s ease-in-out;
}
.dashboard-card .table .btn-icon:hover {
  color: #091747;
}
.status-new,
.status-inspected,
.status-working,
.status-appraised,
.status-offermade,
.status-counteroffer,
.status-finaloffer,
.status-accepted-purchase,
.status-no-deal {
  padding: 6px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #ffffff;
  border-radius: 50px;
}
.status-new,
.offer-details-inner .form-group .form-select.status-new,
.is-total-appraisals .progress .progress-bar {
  background-color: #43B0FB;
}
.status-inspected,
.offer-details-inner .form-group .form-select.status-inspected,
.is-today-appraisals .progress .progress-bar {
  background-color: #eba142;
}
.status-working,
.offer-details-inner .form-group .form-select.status-working,
.is-working .progress .progress-bar {
  background-color: #1E6FF8;
}
.status-appraised,
.offer-details-inner .form-group .form-select.status-appraised,
.is-appraised .progress .progress-bar {
  background-color: #2446CE;
}
.status-offermade,
.offer-details-inner .form-group .form-select.status-offermade,
.is-offermade .progress .progress-bar {
  background-color: #172F8A;
}
.status-counteroffer,
.offer-details-inner .form-group .form-select.status-counteroffer,
.is-counteroffer .progress .progress-bar {
  background-color: #112368;
}
.status-finaloffer,
.offer-details-inner .form-group .form-select.status-finaloffer,
.is-finaloffer .progress .progress-bar {
  background-color: #0B1845;
}
.status-accepted-purchase,
.offer-details-inner .form-group .form-select.status-accepted-purchase,
.is-accepted-purchase .progress .progress-bar {
  background-color: #8bc34a;
}
.status-no-deal,
.offer-details-inner .form-group .form-select.status-no-deal,
.is-no-deal .progress .progress-bar {
  background-color: #f44646;
}
.is-earnings .progress .progress-bar {
  background-color: #1ebbd3;
}
.is-most-accepted .progress .progress-bar {
  background-color: #19ad28;
}
.dashboard-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(33, 33, 33, 0.49);
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  transition: all 0.4s ease-in-out;
}
.dashboard-backdrop.show,
.dashboard-popup.show {
  opacity: 1;
  visibility: visible;
  padding: 150px 0;
  align-items: baseline;
}
.dashboard-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 999;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}
.dashboard-popup-inner {
  background-color: #fff;
  border-radius: 16px;
  padding: 24px;
  min-width: 60vw;
  position: relative;
  z-index: 2;
}
.dashboard-popup-close {
  position: absolute;
  top: 10px;
  right: 7px;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 14px;
  color: #747b87;
  transition: all 0.4s ease-in-out;
}
.dashboard-popup-close:hover {
  transform: rotate(180deg);
}
.top-employee-popup-header {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eeeeee;
}
.top-employee-user {
  display: flex;
  align-items: center;
}
.top-employee-user-image {
  display: inline-block;
  margin-right: 16px;
  vertical-align: middle;
  position: relative;
}
.top-employee-user-image img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 100px;
  border: 2px solid #f29903;
}
.top-employee-user-image::after {
  content: "";
  width: 30px;
  height: 30px;
  background-image: url(../img/star.svg);
  background-repeat: no-repeat;
  background-size: 30px;
  position: absolute;
  top: -12px;
  right: 8px;
}
.top-employee-user h2 {
  display: block;
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  color: #212121;
  margin-bottom: 4px;
}
.top-employee-user p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #212121;
}
.dashboard-popup-title {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  color: #212121;
}
.top-employee-count {
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  border-color: #ededed;
  border-radius: 12px;
  padding: 33px 24px;
}
.top-employee-count-row {
  display: flex;
  flex-wrap: wrap;
}
.top-employee-count-icon {
  width: 40px;
  height: 40px;
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 16px;
  color: #fff;
}
.is-total-won .top-employee-count-icon {
  background-color: #66bf7a;
}
.top-employee-count-count {
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  font-size: 36px;
  line-height: 1.2;
  color: #4b5360;
  display: block;
  margin-bottom: 8px;
}
.top-employee-count-label {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.1;
  color: #4b5360;
  margin: 0;
}
.is-total-appraisals .top-employee-count-icon {
  background-color: #51bdd9;
}
.is-total-amount .top-employee-count-icon {
  background-color: #f19802;
}
.top-employee-popup .dashboard-card {
  border-radius: 16px;
  padding: 8px 16px;
  max-height: 30vh;
  overflow: auto;
}
.appraisal-head-wrap .btn-icon {
  width: 40px;
  height: 40px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  color: #212121;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-right: 16px;
}
.dashboard-button-box .btn-icon:hover,
.appraisal-head-wrap .btn-icon:hover {
  background-color: #e3e3e3;
}
.appraisal-head-wrap .page-title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 1;
  color: #485363;
}
.appraisal-head-title {
  display: flex;
  align-items: center;
}
.appraisal-head-buttons .btn:not(:first-child) {
  margin-left: 16px;
}
.appraisal-head-buttons .btn i {
  margin-right: 11px;
}
.nav-pills .nav-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.19;
  color: #000;
  background-color: #f1f1f1;
  padding: 16px 48px;
  text-transform: capitalize;
}
.appraisal-tabs .nav {
  display: inline-flex;
  background-color: #fff;
  border-radius: 30px;
}
.appraisal-tabs .nav-pills .nav-link.active {
  color: #616161;
  background-color: #fff;
}
.appraisal-tabs .nav-pills .nav-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.19;
  color: #000;
  background-color: #f1f1f1;
  padding: 16px 48px;
  text-transform: capitalize;
  border: 2px solid #fff;
  border-radius: 0px 24px 0px 24px;
  transition: all 0.4s ease-in-out;
}
.appraisal-tabs .nav-pills .nav-item:nth-child(1) .nav-link {
  border-radius: 24px 0px 24px 0px;
}
.appraisal-tabs .nav-pills .nav-item:nth-child(2) .nav-link {
  border-radius: 0px 24px 0px 24px;
}
.appraisal-tabs .tab-pane {
  padding: 24px;
  background-color: #ffffff;
  box-shadow: 0px 61px 34px rgba(0, 0, 0, 0.05);
  border-radius: 0px 24px 24px 24px;
}
.appraisal-form-inner .form-check {
  margin-top: 24px;
}
.appraisal-form-inner .form-group {
  padding: 9px 24px;
  border: 1px solid #e6e6e6;
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.appraisal-form-inner .col-form-label {
  padding: 0 10px 0 0;
  margin-right: 10px;
  border-right: 1px solid #d7d7d7;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.18;
  color: #78818d;
}
.appraisal-form-inner .form-control {
  border: none;
  outline: none;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.18;
  color: #212121;
  padding: 6px 0;
  background-color: transparent !important;
}
.appraisal-form-inner .form-select {
  border: none;
  outline: none;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.18;
  color: #212121;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0px;
  background-color: transparent;
  background-image: url(../img/svg/chevron-down-thin.svg);
  background-position: right center;
}
.appraisal-tabs hr {
  background-color: #e2e2e2;
}
.vehicle-photos-upload {
  background-color: #f7f7f7;
  border: 2px dashed #e6e6e6;
  border-radius: 8px;
  font-size: 26px;
  color: #adadad;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 124px;
}
.vehicle-photo-wrap {
  position: relative;
}
.vehicle-photo-wrap img {
  height: 124px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  min-height: 124px;
}
.vehicle-photo-wrap .icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  height: 25px;
  width: 25px;
  border-radius: 50px;
  color: #4b5360;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vehicle-photos .card-title {
  color: #4b5360;
}
.manager-details-user {
  display: flex;
  align-items: center;
}
.manager-image img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100px;
  margin-right: 16px;
}
.manager-info h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  color: #212121;
  display: block;
  margin-bottom: 4px;
}
.manager-info span {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #4b5360;
}
.offer-details-inner .form-group {
  padding: 12px;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
}
.offer-details-inner  .btn {
  padding: 10px 15px 8px;
  font-size: 14px;
}
.manager-details-inner {
  margin-top: 32px;
  margin-bottom: 41px;
}
.offer-notes-inner {
  max-height: 630px;
  overflow-x: auto;
  padding-right: 15px;
}

.offer-notes-inner.history-notes-inner{
  max-height: calc(100vh - 320px);
}
.offer-notes .appraisal-form-head {
  margin-top: 40px;
  margin-bottom: 25px;
}
.offer-details-inner .form-group .form-label {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #737b87;
  display: block;
}
.offer-details-inner .icon {
  color: #212121;
}
.offer-details-inner .form-group .form-control,
.offer-details-inner .form-group .form-select {
  padding: 8px 15px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 60px;
  border: none;
  outline: none;
  box-shadow: none !important;
  line-height: 1.2;
  cursor: pointer;
  text-overflow: ellipsis;
}
.offer-details-inner .form-group .form-control,
.offer-details-inner .form-group .form-select.with-bg {
  color: #212121;
  background-color: #f2f2f2;
  font-weight: 700;
}
.offer-details-inner .form-group .form-select[name="status"] {
  color: #fff;
  background-image: url(../img/svg/arrow-down-white.svg);
  background-size: 14px;
  text-overflow: ellipsis;
}
.offer-details-inner .form-group .deal_amount {
  background-image: url(../img/svg/edit.svg);
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: right 15px center;
}
.offer-details-inner .form-group .form-select,
.offer-details-inner .form-group .deal_amount {
  padding-right: 32px !important;
}
.offer-details-inner .form-group .form-select option {
  background-color: #fff;
  color: #212121;
}
.offer-details-inner .form-select.form-control {
  color: #212121;
  background-image: url(../img/svg/down-arrow.svg);
  background-position: right 12px center;
}
.offer-note {
  padding: 16px;
  border: 1px solid #e6e6e6;
  border-radius: 16px;margin-bottom: 20px;
}
.offer-note:last-child{margin-bottom: 0;}
.pagination {
  margin-top: 24px;
}
.pagination .nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;
}
.swal2-actions .btn + .btn {
  margin-left: 10px;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: #fb4646 !important;
}
.swal2-icon.swal2-error {
  color: #fb4646 !important;
  border-color: rgb(251 70 70 / 70%) !important;
}
.swal2-icon.swal2-warning {
  border-color: rgb(255 190 51 / 60%) !important;
  color: #ffbe33 !important;
}
.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #55ba1c !important;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border-color: rgb(97 222 27 / 60%) !important;
}
.swal2-icon.swal2-info {
  border-color: rgb(15 185 240 / 60%) !important;
  color: #0fb9f0 !important;
}
.table > :not(:first-child) {
  border-top: none;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #091747;
}
.manager-image {
  display: inline-block;
  position: relative;
}
.manager-image input {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
}
.manager-image i {
  width: 26px;
  height: 26px;
  background-color: #091747;
  border-radius: 60px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 2px;
  right: 12px;
  font-size: 12px;
}

/*---new-style---*/

ol, ul {list-style: none;margin: 0;padding: 0;}
.offer-note-image{width: 39px;height: 39px;overflow: hidden;border-radius: 50%;background: #F3F3F3;position: relative;}
.offer-note-image img{object-fit: cover;height: 100%;}
.offer-note-image .icon{font-size: 20px;position: absolute;color: #babbbf; line-height: 0;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.offer-note-head{display: flex;flex-wrap: wrap;justify-content: space-between;border-bottom: 1px solid #F0F0F0;padding-bottom: 16px;padding-bottom: 9px;margin-bottom: 13px;}
.offer-note-info{max-width: 62vw;}
.offer-note-info p{font-size: 16px;line-height: 1;color: #212121;display: block;font-weight: 700;margin-bottom: 0;}
.offer-note-info span{font-size: 14px;line-height: 1;color: rgba(33, 33, 33, 0.6);font-weight: 400;}
.noted-action-buttons{font-size: 0;}
.noted-action-buttons .btn-icon ~ .btn-icon{margin-left: 12px;}
.noted-action-buttons .btn-icon{border: 1px solid #EDEDED;border-radius: 16px;font-size: 14px;color: #8C96A6;width: 32px;height: 32px;
display: inline-block;position: relative;transition: all 0.4s ease-in-out;vertical-align: middle;}
.noted-action-buttons .btn-icon:hover{color: #091747;}
.noted-action-buttons .btn-icon .icon{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.offer-note-detail{padding-right: 15px;}
.offer-note-detail p{color: #16181C;font-size: 18px;line-height: 1.2;}
.offer-note .col-auto{padding-right: 8px;}
.offer-note .col{padding-left: 8px;}
.offer-note-input{margin-top: 20px;}
.offer-note-input .form-control{border: 1px solid #E6E6E6;background: #F5F5F5;min-height: 51px;padding: 10px 16px;border-radius: 12px;
color: #000;font-weight: 500;font-size: 16px;line-height: 1.4;}
.offer-note-input .form-control::-webkit-input-placeholder {color: #4B5360;}
.offer-note-input .form-control::-moz-placeholder {color: #4B5360;}
.offer-note-input .form-control:-ms-input-placeholder {color: #4B5360;}
.offer-note-input .form-control:-moz-placeholder {color: #4B5360;}
.offer-note-input .form-control:focus{border-color: #ced4da;box-shadow: none;}
.offer-note-input .col-auto{margin-left: 12px;}
.offer-note-input .btn-icon{background: #fff;border-color: #F1F1F1; border-style: solid;border-radius: 12px; border-width: 1px 1px 4px 1px;
color: #212121;font-size: 16px;width: 50px;height: 50px;position: relative;transition: all 0.4s ease-in-out;}
.offer-note-input .btn-icon:hover{background-color: #59C176;color: #fff;border-color: #4BAF67;}
.offer-note-input .btn-icon .icon{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.upload-button{cursor: pointer; position: relative;z-index: 2;border: 1px solid #EDEDED;border-radius: 50px;min-width: 147px;padding: 9px 10px;font-size: 16px;color: #212121;
line-height: 1.4;display: inline-block;vertical-align: middle;transition: all 0.4s ease-in-out;}
.upload-button:hover{background: #fafafa;}
.upload-button .icon{font-size: 15px;color: #8C96A6;margin-right: 5px;}
.browse-btn{position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;font-size: 0;padding: 0;border: none;background: transparent;
opacity: 0;cursor: pointer;}
.vehicleuplaodcol{margin-top: 24px;}
.history-date{text-align: center;position: relative;margin-top: 38px;margin-bottom: 35px;}
.history-date .date-Title{font-size: 18px;font-weight: 500;color: #4B5360;display: inline-block;overflow: hidden;vertical-align: middle;
background: #fff;position: relative;z-index: 3;padding: 0 16px;}
.history-date:before{content: "";position: absolute;top: 50%;height: 1px;width: 100%;display: block;border-bottom: 1px solid #F1F1F1;right: 0;left: 0;}
.history-notes-inner .offer-note-info p{font-size: 20px;margin-bottom: 6px;}
.history-notes-inner .offer-note-info p span{color: #212121;font-weight: 400;}
.history-notes-inner .offer-note-info span{font-size: 16px;}
.history-notes-inner .offer-note-head{padding-bottom: 0;border-bottom: none;}
.history-note-detail .form-group .amoiunt{padding: 8px 12px;border-radius: 50px;background: #F2F2F2;font-weight: 700;font-size: 14px;line-height: 1.3;
color: #212121;display: inline-block;}
.history-note-detail.offer-details-inner .form-group .form-label{margin-bottom: 15px;}
.historynotecol{width: auto;}
.mw-162{min-width: 162px;}
.mw-168{min-width: 168px;}
.mw-153{min-width: 153px;}
.w-522{width: 522px;}
.arrow-icon{width: 40px;height: 40px;border: 1px solid #F9F9F9;border-radius: 50%;font-size: 16px;color: #33577B;position: relative;
display: inline-block;transform: rotate(180deg);}
.arrow-icon .icon{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.history-notes-inner .form-group ul{padding: 0;margin-bottom: 0;font-size: 0;}
.history-notes-inner .form-group ul li{list-style: none;font-size: 16px;font-weight: 500;color: #212121;line-height: 1;
display: inline-block;vertical-align: top;width: 50%;margin-bottom: 33px;}
.history-notes-inner .form-group ul li span{color: #78818D;border-right: 1px solid #D7D7D7;margin-right: 10px;padding-right: 10px;
display: inline-block;width: 58px;}
.history-notes-inner .form-group ul li b{font-weight: 500;width: calc(100% - 73px);display: inline-block;vertical-align: top;}
.history-notes-inner .form-group ul li ~ li{margin-bottom: 0;}
.history-notes-inner .form-group ul li:last-child{margin-bottom: 0;}
.offer-details-inner.history-note-detail .row{margin-bottom: 0;}

.pagination{display: block;text-align: center;}
.nav-links{display: inline-block;font-size: 0;}
.page-numbers.prev, .page-numbers.next{line-height: 1;font-size: 16px;color: #747B87;}
.page-numbers .icon{position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);}
.page-numbers.prev{margin-right: 26px;margin-left: 0;}
.page-numbers.next{margin-left: 26px;margin-right: 0;}
.pagination .nav-links li a{width: 38px;height: 38px;border: 1px solid #E4E4E4;border-radius: 50%;display: inline-block;vertical-align: top;position: relative;
line-height: 38px;font-family: 'Work Sans', sans-serif;font-size: 16px;color: #747B87;font-weight: 600;transition: all 0.4s ease-in-out;margin: 0 4px;}
.pagination .nav-links li a.current,.pagination .nav-links li a:hover{border-color: transparent;background: #091747;color: #fff;}
.dashboard-navbar-search{position: relative;}
.dashboard-latest-appraisals .dashboard-navbar-search{display: inline-block;vertical-align: top;}
.dashboard-latest-appraisals .dashboard-navbar-search .form-control{width: 19vw;}
.page-dec{color: #747B87;font-size: 16px;font-family: "Roboto", sans-serif;font-weight: 400;line-height: 1;}
.dashboard-navbar-date-filter{display: inline-block;vertical-align: top;}
.dashboard-navbar-date-filter .date-filter{border: 1px solid #EDEDED;border-radius: 50px;padding: 8px 16px;font-size: 16px;color: #212121;font-weight: 500;background-color: transparent;
line-height: 1.4;text-align: center;display: inline-block;transition: all 0.4s ease-in-out;}
.dashboard-navbar-date-filter .date-filter .icon{font-size: 14px;color: #9A9A9A;margin-left: 5px;}
.dashboard-navbar-date-filter .date-filter:hover,.react-daterange-picker__button:hover{background: #f7f7f7;}
.dashboard-appraisals .page-title{margin-bottom: 10px;}
.form-control:focus{box-shadow: none;}
.get-help-popup .dashboard-popup-inner{background: #FBFBFB;border-radius: 24px;border: 2px solid #FFFFFF;width: 840px;min-width: auto; padding: 46px 48px;}
.get-help-Title{font-size: 32px;font-weight: 700;line-height: 1.2;color: #212121;margin-top: 0px;margin-bottom: 53px;}
.question-search{width: 542px;display: block;margin: auto;margin-bottom: 48px;}
.question-search .form-group{position: relative;background: #FFFFFF;box-shadow: 0px 9px 22px rgba(81, 105, 124, 0.1);border-radius: 8px;}
.question-search .form-group .form-control{display: inline-block;font-weight: 500;font-size: 14px;line-height: 1.4;color: #212121;
width: 100%;border: none;position: relative;padding: 10px 20px;min-height: 50px;padding-right: 40px;font-family: 'Poppins', sans-serif;}
.question-search .form-group .form-control::-webkit-input-placeholder {color: #747B87;font-weight: 500;}
.question-search .form-group .form-control::-moz-placeholder {color: #747B87;font-weight: 500;}
.question-search .form-group .form-control:-ms-input-placeholder {color: #747B87;font-weight: 500;}
.question-search .form-group .form-control:-moz-placeholder {color: #747B87;font-weight: 500;}
.question-search .form-group .form-control::placeholder {color: #747B87;font-weight: 500;}
.question-search .form-group .search-button{display: inline-block;font-size: 16px;color: #cccccc;position: absolute;top: 0;bottom: 0;right: 0;margin: auto;
border: none;background: transparent;width: 54px;}
.helpyouboxMain{margin-bottom: 100px;}
.helpyoubox{background: #FFFFFF;box-shadow: 0px 24px 74px rgba(0, 0, 0, 0.03);border-radius: 16px;padding: 22px 20px;height: 100%;cursor: pointer;}
.helpyoubox.active{background: #e9e9e9;}
.helpyouboximg{width: 20px;height: 20px;margin-left: auto;margin-right: auto; margin-bottom: 10px;}
.helpyouboxTitle{font-weight: 600;font-size: 12px;font-family: 'Poppins', sans-serif;color: #4B5360;}
.helpyoubox p{font-size: 12px;font-weight: 400;line-height: 1.2;color: #747B87;}
.faqMian .secHead{max-width: 65%;margin-left: auto;margin-right: auto;margin-bottom: 56px;}
.secHead{margin-bottom: 20px;}
.secHead .secTitle{font-weight: 700;font-size: 32px;color: #212121;}
.secHead p{font-weight: 400;font-size: 16px;color: #8E8E8E;line-height: 1.2;}
.faq-accordionMian .accordion-item{border-radius: 16px;overflow: hidden;margin-bottom: 12px;border: 0;}
.faq-accordionMian .accordion-item:last-child{margin-bottom: 0;}
.faq-accordionMian .accordion-button{font-weight: 500;font-size: 20px;line-height: 1.4;font-family: 'Poppins', sans-serif;color: #4B5360;
padding: 19px 24px;padding-right: 55px;}
.accordion-button:focus{border-color: transparent;box-shadow: none;}
.faq-accordionMian .accordion-button:not(.collapsed){background-color: transparent;color: #212121;box-shadow: none;}
.faq-accordionMian .accordion-body{padding: 24px 24px;padding-top: 0;}
.faq-accordionMian .accordion-body.cmsCon p{color: #212121;line-height: 1.4;}
.faq-accordionMian .accordion-button::after{content: "";position: absolute;left: inherit;top: 0;bottom: 0;right: 32px;margin: auto;
display: inline-block;background-image: url(../img/svg/plus.svg);object-fit: contain;object-position: center;
background-size: 10px;background-repeat: no-repeat;width: 10px;height: 10px;}
.faq-accordionMian .accordion-button:not(.collapsed)::after{background-image: url(../img/svg/minus.svg);background-repeat: no-repeat;height: 2px;}
.faq-accordionMian .accordion-button::before{position: absolute;content: "";width: 26px;height: 26px;background: #F7F7F7;border-radius: 50%;
top: 0;right: 24px;bottom: 0;margin: auto;}
.contactMian .secHead{margin-top: 39px;}
.contactMian .secHead .secTitle{margin-bottom: 7px;}
.contactMian .secHead p{color: #4B5360;font-weight: 500;line-height: 1.4;}
.contactMian .secHead .btn{min-width: 132px;font-size: 14px;padding: 11px 24px 8px;}
.secHead .secTitle.size24{font-size: 24px;}

.continfo{margin-bottom: 42px;}
.continfo ul li{display: block;margin-bottom: 27px;line-height: 0;}
.continfo .infodetail{font-size: 12px;font-weight: 500;line-height: 1;font-family: 'Poppins', sans-serif;color: #212121;position: relative;padding-left: 22px;
transform: all 0.4s ease-in-out;}
.continfo a.infodetail:hover{color: #091747;}
.continfo .infodetail .icon{color: #747B87;position: absolute;top: 2px;left: 0;}
.continfo ul li:last-child{margin-bottom: 0;}
.contactinfoMian .secHead{margin-bottom: 42px;max-width: 85%;}
.contactinfoMian .secHead p{font-size: 12px;}

.socialMeadia a{width: 32px;height: 32px;border-radius: 50%;background: rgba(163, 163, 163, .1);font-size: 13px; color: #A3A3A3;display: inline-block;position: relative;transition: all 0.4s ease-in-out;
margin: 0 12px;}
.socialMeadia a:first-child{margin-left: 0px;}
.socialMeadia a:last-child{margin-right: 0px;}
.socialMeadia a .icon{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);line-height: 0.8;}
.socialMeadia .instagram:hover{background: #8a3ab9;color: #fff;}
.socialMeadia .twitter:hover{background: #00acee;color: #fff;}
.socialMeadia .youtube:hover{background: #FF0000;color: #fff;}

.contact-inner{box-shadow: 0px 24px 74px rgb(0 0 0 / 3%);border-radius: 24px;background-color: #fff;padding: 40px 40px;}
.contactinforow .contactinfocol:first-child{width: 59%;}
.contactinforow .contactinfocol:last-child{width: 41%;}
.contact-inner .secHead{margin-bottom: 22px;}
.contactinfoMian .secHead .secTitle{margin-bottom: 12px;}
.contact-form .login-form-inner .form-label{font-weight: 600;font-family: 'Poppins', sans-serif;}
.contact-form .login-form-inner .btn{margin-bottom: 0;}
.contact-form .login-form-inner .form-control.textareaField{border-radius: 16px;min-height: 126px;}

.contact-form .login-form-inner .form-control::-webkit-input-placeholder {color: #4B5360;}
.contact-form .login-form-inner .form-control::-moz-placeholder {color: #4B5360;}
.contact-form .login-form-inner .form-control:-ms-input-placeholder {color: #4B5360;}
.contact-form .login-form-inner .form-control:-moz-placeholder {color: #4B5360;}
.contactimg{margin-bottom: -1px;}
.contactdetails{padding-top: 41px;}

.dashboard-navbar-month{min-width: auto;display: inline-block;vertical-align: top;}
.dashboardml ~ .dashboardml{margin-left: 14px;}
.dashboard-navbar-month .form-select{font-weight: 500;background-image: url(../img/svg/down-arrow.svg);background-size: 9px;    max-width: 230px; text-overflow: ellipsis;}
.dashboard-navbar-filter{min-width: 48px;display: inline-block;}
.dashboard-navbar-filter .filter{border: 1px solid #EDEDED;padding: 7px 15px;
border-radius: 50px;position: relative;display: inline-block;transition: all 0.4s;}
.dashboard-navbar-filter .filter:hover{background: #f7f7f7;}
.dashboard-navbar-filter .filter .icon{font-size: 16px;color: #9A9A9A;}
.dashboard-export .btn{font-size: 14px;padding: 11px 22px 8px;vertical-align: middle;}
.dashboard-export .btn .icon{font-size: 15px;display: inline-block; vertical-align: top;}
.appraisal-head-wrap .page-title{margin-bottom: 10px;}
.appraisal-head-wrap .page-dec{font-weight: 600;}
.appraisal-head-wrap.notifications-head-wrap .page-title{font-weight: 500;font-size: 40px;color: #212121;}
.appraisal-head-wrap.notifications-head-wrap .page-dec{font-weight: 400;}

.notification{background: #FFFFFF;box-shadow: 0px 24px 74px rgba(0, 0, 0, 0.03);border-radius: 24px;max-width: 920px;margin-left: auto;
margin-right: auto;padding: 32px;padding-top: 45px;}
.notification-Title{font-weight: 600;font-size: 24px;color: #4B5360;display: block;margin-bottom: 24px;}
.ffr{font-family: 'Roboto', sans-serif;}
.ffp{font-family: 'Poppins', sans-serif;}
.dashboard-notification{border: 1px solid #EDEDED;border-radius: 16px;}
.dashboard-notification .table thead th, .dashboard-notification .table tbody td{padding: 17px 16px;color: #1D1E1F;font-weight: 500;font-size: 14px;line-height: 1;white-space: nowrap;
text-align: center;border-right: 1px solid #EDEDED;border-bottom: 1px solid #EDEDED;box-shadow: none;}
.dashboard-notification .table thead th:last-child, .dashboard-notification .table tbody td:last-child{border-right: 0;}
.dashboard-notification .table > :not(:last-child) > :last-child > *{border-bottom-color: #EDEDED;}
.dashboard-notification .table tbody tr td{font-weight: 400;vertical-align: middle;}
.dashboard-notification .table tbody tr:last-child td{border-bottom: 0;}
.dashboard-notification .table tbody tr td:first-child{text-align: left;}

.customCheckbox{position: relative;line-height: 0;vertical-align: text-bottom;display: inline-block;margin-right: 5px;}
.customCheckbox input[type="checkbox"]{position: absolute; left: 0; opacity: 0; top: 0; left: 0;} 
.customCheckbox > .lable-text{display: inline-block; width: 18px;height: 18px; position: relative;cursor: pointer;}
.customCheckbox > .lable-text:before{content: ""; position: absolute; left: 0; top: 0; bottom: 0; margin: auto;  width: 18px; height: 18px; border: 2px solid #CCCCCC; background: #ffffff; border-radius: 5px;} 
.customCheckbox > .lable-text:after{content: ""; -webkit-transform: translate(4px,5.38px) rotate(-45deg); transform: translate(4px,5.38px) rotate(-45deg); width: 8px; height: 5px; border: 2px solid #fff; border-top-style: none; border-right-style: none; opacity: 0; transition: all .4s ease-in-out; position: absolute; left: 1px; top: 0px; bottom: 13px; margin: auto;} 
.customCheckbox.darkCheck > .lable-text:after{border-color: #28304b;}
.customCheckbox input[type="checkbox"]:checked+.lable-text:before { background-color: #59C176; border-color: #59C176;}
.customCheckbox input[type="checkbox"]:checked+.lable-text:after { opacity: 1;}

.customradio.customCheckbox > .lable-text:before{ border-radius: 50%;} 
.customradio.customCheckbox input[type="radio"]{position: absolute; left: 0; opacity: 0; top: 0; left: 0;} 
.customradio.customCheckbox input[type="radio"]:checked+.lable-text:before { background-color: #59C176; border-color: #59C176;}
.customradio.customCheckbox input[type="radio"]:checked+.lable-text:after { opacity: 1;}

.dashboard-switch-button{display: inline-block;vertical-align: top;}
.dashboard-switch-button .switch-buttonmain{border: 1px solid #EDEDED;border-radius: 50px;padding: 6px 22px;padding-right: 10px; font-size: 12px;color: #433C52;font-weight: 500;
line-height: 1.2;text-align: left;display: inline-block;transition: all 0.4s ease-in-out;background: #fff;}
.switch-button-text{display: inline-block;vertical-align: middle;}
.switch-button{display: inline-block;vertical-align: middle;margin-left: 5px;}
.switch-button .switch {display: inline-block;position: relative;width: 42px;height: 23px;border-radius: 50px;background: #ccc;transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
vertical-align: middle;cursor: pointer;}
.switch-button .switch::before {content: '';position: absolute;top: 2px;right: 21px;width: 19px;height: 19px;background: #fff;border-radius: 50%;
transition: right 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);}
.switch-button .switch:active::before {box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(128,128,128,0.1);}
.switch-button input:checked + .switch {background: #66BF7A;}
.switch-button input:checked + .switch::before {right: 2px;left: unset;background: #fff;}
.switch-button input:checked + .switch:active::before {box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(0,150,136,0.2);}

.appraisal-form-inner .form-control.password { padding-left: 26px;}
.appraisal-form-inner .form-control.password {background-image: url(../img/svg/password-2.svg);background-repeat: no-repeat;background-position: center left;}

.notification.edit-profile{padding: 32px;}
.edit-profile .manager-image img{width: 80px;height: 80px;}
.edit-profile .manager-info h4{font-size: 24px;}
.edit-profile .btn{font-weight: 700;}
.edit-profile .btn .icon{margin-right: 6px;}
.edit-profile .btn .icon.icon-edit{color: rgba(75, 83, 96, 0.5);}
.edit-profile .manager-details-inner{margin-bottom: 22px;}
.edit-profile .appraisal-form-inner .form-group{padding: 9px 16px;}
.login-inner.add-new-user-inner{margin-right: auto;max-width: 538px;padding: 32px;padding-bottom: 38px;}
.login-head{border-bottom: 1px solid #F4F4F4;}
.add-new-user-inner .login-head{padding-bottom: 30px;margin-bottom: 30px;}
.add-new-user-inner .login-text{margin-bottom: 0;}
.form-control.form-select{background-image: url(../img/svg/chevron-down-thin.svg);background-position: right 20px center;background-size: 10px;
padding-right: 35px;}
.notice{position: relative;padding-left: 30px;color: #212121;margin-bottom: 24px;}
.notice p{color: #212121;}
.notice:before{position: absolute;content: "";width: 20px;height: 20px;background: url(../img/svg/notice.svg) no-repeat;top: 2px;left: 0;}
.intl-tel-input{padding: 0 10px 0 0;margin-right: 10px;border-right: 1px solid #d7d7d7;}
.login-form-inner .form-control.phone{padding-left: 38px;background-image: url(../img/svg/phone-line.svg);background-size: 12px;}
.tel-country-list{font-weight: 500;font-size: 16px;color: #78818D;border: none;}
.tel-country-list:focus{box-shadow: none;border: none;outline: none;}
.inputField{border: none; outline: none;box-shadow: none !important;font-weight: 500;font-size: 16px;line-height: 1.18;color: #212121;padding: 0;
background-color: transparent;margin: 0;}
.add-new-user-inner .btn{font-weight: 500;}
.add-new-user-inner .btn .icon{margin-right: 5px;}
.login-form-login-link a.cancel{margin-left: 0;font-weight: 400;text-decoration-line: none;}

.notifitimedaytext{font-weight: 500;font-size: 14px;color: #212121; display: inline-block;vertical-align: middle;}
.noted-action-buttons .status-new{background: #091747;display: inline-block;vertical-align: middle;}
.dashboard-notification .offer-note-info p{font-size: 18px;}
.dashboard-notification .offer-note-info p span{font-weight: 500;font-size: 18px;color: #212121;}
.dashboard-notification .offer-note-head{padding-bottom: 0;border-bottom: 0;margin-bottom: 0;}
.dashboard-notification .offer-note-detail p{font-size: 16px;}
.dashboard-notification .offer-note-detail{max-width: 80%;}
.dashboard-notification .noted-action-buttons .btn-icon{color: #212121;}
.dashboard-notification .noted-action-buttons .btn-icon:hover{color: #091747;}
.offer-note.bglight{background: #F8F8F8;}
.dashboard-notification .offer-note-image{width: 48px;height: 48px;}
.dashboard-notification .history-date{margin-top: 30px;}
.login-section .login-form-inner .btn{/*border-width: 0;*/padding: 17px 24px 14px;}
.login-section .login-form-register-link{border: none;background-color: #EEFCFF;}
.login-section .login-form-register-link .icon{font-size: 20px;margin-right: 5px;}
.login-section .login-inner{padding-bottom: 70px;}
.user-reset-password-section{background: #FBFBFB;}

.user-reset-password-section{padding-top: 110px;padding-bottom: 38px;}
.user-reset-password-section .login-inner{margin-bottom: 40px;max-width: 538px;margin-right: auto;}
.border-width-0{border: 0;}
.user-reset-password-section .login-form-inner .btn{padding: 18px 24px 15px;margin-bottom: 15px;}

.form-group .form-control:disabled, .form-control[readonly]{background-color: #F8F8F8;border: none;color: #4D4F53;}
.form-group .form-control.disabled{background-color: #F8F8F8;border: none;color: #4D4F53;}
.form-group .form-control.disabled .inputField{font-weight: 700;color: #4D4F53;}
.form-group.disabled{background-color: #F8F8F8;border: none;color: #4D4F53;}
.form-control[disabled],.form-select[disabled],.react-date-picker--disabled{cursor: default !important;}
.form-limit-text{font-size: 13px;color: #8e8e8e;}

.inputimg{width: 62px;display: flex;}
.inputimg img{object-fit: contain;}
.inputflex{flex: 1 1 auto;width: 1%;}
.form-group-dec{font-weight: 500;font-size: 12px;color: #0194BA;margin-top: 8px;}
.form-group-dec a{color: #0194BA;text-decoration-line: underline;}
.form-group-dec a:hover{color: #091747;}
.no-appraisalimg{max-width: 171px;margin-left: auto;margin-right: auto;margin-bottom: 30px;}
.no-appraisalMain{padding-top: 136px;}
.no-appraisalMain .secHead{margin-bottom: 28px;}

.login-inner.add-new-appraisal-inner{margin-right: auto;max-width: 100%;padding: 32px;padding-bottom: 40px;}

.add-new-appraisal-inner hr{border-bottom: 1px solid #e8e8e8;background-color: #e8e8e8;}
.add-new-appraisal-inner .secHead{margin-bottom: 45px;}
.new-appraisal-card-submit .btn{min-width: 100%;}
.newcard-submitrow{margin: 0 -8px;}
.newcard-submitcol{padding: 0 8px;}
.new-appraisal-card-submit .form-privacy-policy{margin-top: 43px;}
.add-new-appraisal-inner .vehicle-photos{padding: 16px 0; display: block;}

.form-group{display: block;position: relative;}
.form-group .form-error, .form-group label.error {position: absolute; right: 0; top: -20px; width: auto; height: auto; margin: 0; font-size: 11px!important; line-height: 11px!important; background: #fb0101; padding: 5px 5px 4px!important; color: #fff!important;}
.form-group .form-error:after, .form-group label.error:after {border-color: #fb0101 transparent transparent; -o-border-image: none;
 border-image: none; border-right: 6px solid transparent; border-style: solid; border-width: 6px; content: ""; height: 0; left: 5px; position: absolute; top: 100%; width: 0;}

.form-group input:-webkit-autofill, .form-group input:-webkit-autofill:hover, 
.form-group input:-webkit-autofill:focus, .form-group textarea:-webkit-autofill, .form-group textarea:-webkit-autofill:hover, 
.form-group textarea:-webkit-autofill:focus, .form-group select:-webkit-autofill, .form-group select:-webkit-autofill:hover,
.form-group select:-webkit-autofill:focus{background-color:transparent;-webkit-text-fill-color:#000;-webkit-box-shadow:0 0 0px 1000px transparent inset;box-shadow:0 0 0px 1000px transparent inset;transition:background-color 5000s ease-in-out 0s}

.appraisal-listing-filter .page-title{margin-bottom: 10px;}
.appraisal-car-listMian{font-size: 0;}
.appraisal-listing-popup .dashboard-popup-close{background: #fff;width: 34px;height: 34px;font-size: 13px;color: #000;display: inline-block;top: -17px;right: -17px;
border-radius: 50%;line-height: 34px;z-index: 2;}
.dashboard-popup-inner.appraisal-listing-popup{width: 1087px;min-width: 1087px;padding: 0;border-radius: 24px;}
.carlistimg{position: relative;display: block;width: 100%; padding-bottom: 49%;}
.carlistimg img{position: absolute;top: 0;right: 0;bottom: 0;left: 0;width: 100%;height: 100%;max-width: 100%;max-height: 100%;object-fit: cover;
object-position: center;border-radius: 24px;}


/*  Slick slider */

.carlistslide .slick-slide{outline: none;}

.carlistslide .slick-dots{display: block; text-align: center; margin-top: 3.5rem;}

.carlistslide .slick-dots li{ display: inline-block; width:auto; list-style: none; padding: 0 4px;}

.carlistslide .slick-dots li button{font-size:0; border: none; background: rgba(255, 255, 255, 0.5); width: 8px; height: 8px; border-radius: 50%; -moz-transition: all 0.4s ease-in-out; -webkit-transition: all 0.4s ease-in-out;  transition: all 0.4s ease-in-out; cursor: pointer; outline: none;
position: relative;padding: 0;}

.carlistslide .slick-dots li.slick-active button, .carlistslide .slick-dots li button:hover{background: #fff;}

.carlistslide .slick-prev{background: #fff;width: 44px;height:44px;border-radius:50%;position: absolute;left: 37px;top:50%;margin-top:-20px;z-index:999;font-size:0;border:none;outline-color: transparent;
transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out, transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;}

.carlistslide .slick-prev:before{content:"\e908";width: auto;height: auto;font-family: "icon-pack-v1" !important;
  font-style: normal !important;font-size: 16px;line-height: 44px;
  font-weight: 700;position:absolute;left:0px;right:0;top:0px;bottom:0;margin:auto;}

.carlistslide .slick-next{background:#fff; width:44px; height:44px; border-radius:50%; position: absolute; right:37px; top:50%; margin-top:-20px; z-index:999; font-size:0; border:none; outline-color: transparent;
transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out, transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;}

.carlistslide .slick-next:before{content:"\e909"; width:auto; height:auto;font-family: "icon-pack-v1" !important;
  font-style: normal !important;font-size: 16px;line-height: 44px;
  font-weight: 700; position:absolute; left:0; right:0px; top:0px; bottom:0; margin:auto;}

.carlistslide .slick-prev:arrow .slick-next:arrow{ color: #000000;}

.carlistslide .slick-prev:hover, .slick-next:hover {color: #bcbcbc;}
/* Slick slider End */


.carlistslide .slick-dots{position: absolute;margin-top: 10px;bottom: 29px;left: 0;right: 0;}
.appraisalsvehicle{position: relative;z-index: 2;transition: all 0.4s ease-in-out;}
.appraisalsvehicle:hover:before{content: ""; position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 14px;height: 14px;background: url(../img/svg/imgzoom.svg) no-repeat;
margin: auto;}

.show-earningsMain{background: linear-gradient(92.29deg, #45A35A 8.73%, #E1F8A0 103.41%);border-radius: 16px;max-width: 753px;margin-left: auto;margin-right: auto;
padding: 20px 30px;margin-bottom: 24px;margin-top: 160px;}
.earnings-head .earnings-Title{position: relative;padding-left: 39px;font-weight: 500;font-size: 24px;color: #fff;margin: 0;}
.earnings-head .earnings-Title .icon{position: absolute;top: 3px;left: 0;font-size: 22px;}
.show-earningsMain .switch-button input:checked + .switch{background: #fff;}
.show-earningsMain .switch-button input:checked + .switch::before{background: #66BF7A;right: 29px;}
.show-earningsMain .switch-button .switch{width: 52px;height: 26px;}
.show-earningsMain .switch-button .switch::before{top: 4px;right: 4px;background: #66BF7A;}
.show-earningsMain .switch-button .switch{background: #fff;}

.my-edit-profile{margin-top: 60px;max-width: 920px;}
.btn ~ .btn{margin-left: 11px;}

.one-purchaseMian{padding: 5px 0;}
.one-purchaseMian .purchaseTitle{color: #73787C;font-weight: 600;margin-bottom: 20px;}
.one-purchaseMian .usdprice{font-weight: 600;color: #56AC69;font-size: 32px;line-height: 1;margin-bottom: 0;}
.one-purchaseMian .border-dashed{position: relative;display: block;border-bottom: 1px dashed #7592A9;}
.one-purchaseMian .unlimiteduser{font-weight: 500;font-size: 17px;color: #3C4C58;line-height: 1;margin-bottom: 0;}
.one-purchaseMian .purchase-dec{font-size: 12px;line-height: 1.2;color: #4B5360;margin-top: 19px;}
.one-purchaseMian .purchase-dec a{text-decoration-line: underline;color: #4B5360;transition: all 0.4s ease-in-out;}
.one-purchaseMian .purchase-dec a:hover{color: #091747;}
.purchaserow{margin: 0 -8px;}
.purchaserow .purchasecol{padding: 0 8px;}
.purchase-section .login-title{margin-bottom: 28px;}
.purchase-section .login-form{margin-top: 24px;}
.purchase-section.login-section .login-inner{padding-bottom: 40px;margin-top: 110px; margin-bottom: 55px;}
.purchase-section .login-copyright{margin-bottom: 30px;}
.form-control::placeholder{color: #4B5360 !important;}

.successfulpayMian{background: #EDFFF1;border: 1px solid #CCF9D6;border-radius: 16px;display: block;padding: 15px 32px;margin-bottom: 32px;}
.successfulimg{max-width: 36px;margin-left: auto;margin-right: auto;margin-bottom: 20px;}
.successfulTitle{font-weight: 700;font-size: 24px;line-height: 1.2;color: #074515;display: block;}
.successfulpayMian p{color: #074515;}
.successful-section .login-title{margin-bottom: 40px;}
.successful-section .btn{margin-bottom: 33px;}
.successful-section.login-section .login-inner{padding-bottom: 55px;}

.notificationboxMain{position: relative;}
.notificationbox{list-style: none;margin: 0;padding: 0;position: absolute;z-index: 9;top: 125%;right: inherit;background-color: #fff;padding: 20px;
box-shadow: 0px 10px 15px -10px #dfdfdf; border-radius: 10px; border: 1px solid #e6e6e6; opacity: 0; visibility: hidden;width: 273px;
left: 50%;transform: translateX(-50%);}
.notificationboxMain .notificationbox.show{opacity: 1; visibility: visible;}
.notifiTitle{font-size: 18px;font-weight: 700;color: #212121;}
.notification-close{border: none;outline: none;box-shadow: none;color: #747b87;font-size: 12px;transition: all 0.4s ease-in-out;background: transparent;
position: absolute;top: 10px;right: 10px;}
.notifibox{display: block;margin-top: 13px;max-height: 40vh;padding-right: 10px;overflow: auto;}
.notifibox ul li{cursor: pointer;display: block;border-bottom: 1px solid #ededed;font-size: 14px;line-height: 1.2; padding-bottom: 4px;margin-bottom: 7px;}
.notifibox ul li span{display: block;font-size: 12px;color: #747b87;font-weight: 400;margin-top: 7px;}
.notifibox li.is-read{opacity: 0.5;}

.viewmore{text-decoration-line: underline;cursor:pointer;color: #091747;font-size: 14px;font-weight: 500;}
.viewmore.viewmore-pink{color: #000;}
.viewmore.viewmore-pink:hover{color: #091747;}
.viewmore:hover{color: #000;}

.serchbox-dropdown{list-style: none;margin: 0;position: absolute;left: 0;z-index: 9;background-color: #fff;padding: 15px;
box-shadow: 0px 10px 15px -10px #dfdfdf; width: 100%; border-radius: 10px; border: 1px solid #e6e6e6;}
.searchboxlist{max-height: 44vh;overflow: auto;height: 100%;}
.searchboxlist ul li{margin-bottom: 10px;padding-bottom: 10px;border-bottom: 1px solid #ededed;}
.searchboxlist ul li:last-child{padding-bottom: 0;margin-bottom: 0;border-bottom: none;}
.vehicle-image{display: inline-block;vertical-align: middle; margin-right: 8px;overflow: hidden;}
.vehicle-image img{width: 40px;height: 40px;border-radius: 4px;object-fit: cover;}
.vehicle-Head{display: inline-block;vertical-align: middle;}
.vehicle-Head .vehicle-Title{font-size: 16px;color: #000;display: block;font-weight: 700;line-height: 1.2;margin-bottom: 4px;}
.vehicle-Head p{font-size: 14px;color: #747b87;display: block;line-height: 1.2;font-weight: 400;}


.form-group input:-webkit-autofill, .form-group input:-webkit-autofill:hover, 
.form-group input:-webkit-autofill:focus, .form-group textarea:-webkit-autofill, .form-group textarea:-webkit-autofill:hover, 
.form-group textarea:-webkit-autofill:focus, .form-group select:-webkit-autofill, .form-group select:-webkit-autofill:hover,
.form-group select:-webkit-autofill:focus{background:transparent;-webkit-text-fill-color:#000;-webkit-box-shadow:0 0 0px 1000px transparent inset;box-shadow:0 0 0px 1000px transparent inset;transition:background-color 5000s ease-in-out 0s}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){margin-left: 0;}
.settings-notification.notification .input-group{align-items: center;}
.settings-notification.notification:not(.no-border) .form-group .form-control{border: 1px solid #e6e6e6;padding: 9px 16px;box-shadow: none !important;font-weight: 500;
font-size: 16px;line-height: 1.18;color: #212121;background: transparent;}
.settings-notification.notification .input-group .customChecklabel{margin-right: 12px;}
.settings-notification.notification .input-group .form-group{position: relative;margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;border-radius: 4px;border: 1px solid #ced4da;}
.settings-notification.notification .input-group .input-group-text{background: transparent;border: none;}
.settings-notification.notification:not(.no-border) .input-group .form-control{border: none;
    flex: 1 1 auto;max-width: 150px;
    width: 1%;}
    .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;}
    .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu){border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;}
    .settings-notification.notification .input-group .customChecklabel{margin-right: 10px;}
    .pl-0{padding-left: 0 !important;}
    .pr-0{padding-right: 0 !important;}
    .settings-notificationbox .btn .icon{margin-right: 6px;}
    .label-text{white-space: normal;}
    .label-text.w-50{width: auto !important;}
    .notification.usernotification{max-width: 753px;}
/*---new-style---*/




/* date range */
.react-calendar {
  width: 367px;
  max-width: 100%;
  background: #ffffff;
  border: 1px solid #ededed;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font-size: 17px;
  border-radius: 0;
}
.react-calendar__tile--hover.react-calendar__tile--now,
.react-calendar__tile--range.react-calendar__tile--now {
  border-radius: 0 !important;
}
.react-calendar__tile--rangeStart:not(.react-calendar__tile--rangeBothEnds) {
  border-radius: 50px 0 0 50px !important;
}
.react-calendar__tile--rangeEnd:not(.react-calendar__tile--rangeBothEnds) {
  border-radius: 0 50px 50px 0 !important;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: none !important;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #ededed;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #ededed;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #091747;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #091747;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #e9e9e9;
  color: #999; 
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #091747;
  color: #fff;
}
.react-calendar__tile--now {
  border-radius: 50px !important;
  color: #000 !important;
  background-color: #d0d0d0 !important;
}
.react-calendar__tile--now {
  background: #091747;
  color: #fff;
}
.react-calendar__viewContainer{padding-bottom: 10px;}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #091747;color: #fff;

}
.react-calendar__tile--hasActive {
  background: #091747;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #091747;
  color: #fff;
}
.react-calendar__month-view__days__day--neighboringMonth:hover {
  color: #fff;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #091747;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #091747;
  color: white;
}

.react-daterange-picker {
  display: inline-flex;
  position: relative;
}
.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-daterange-picker--disabled {
  background-color: #091747;
  color: white;
}
.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: thin solid gray;
}
.react-daterange-picker__inputGroup {
  min-width: auto;
  height: 100%;
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-daterange-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0;
  border: 0;
  background: none;
  outline: none;
}
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-daterange-picker__inputGroup__input:invalid {
  background: transparent;
  outline: none;
}
.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-daterange-picker__button {
  border: 0;
  background: transparent;
  padding: 0 6px;
}
.react-daterange-picker__button:enabled {
  cursor: pointer;
}
.react-daterange-picker__button:enabled:hover
  .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus
  .react-daterange-picker__button__icon {
  stroke: #091747;
  background: transparent;
}
.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
  stroke: #6d6d6d;
}
.react-daterange-picker__button svg {
  display: inherit;
  stroke: #000;
}
.react-daterange-picker__calendar {
  width: 100%;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-daterange-picker__calendar--closed {
  display: none;
}
.react-daterange-picker__calendar .react-calendar {
  border-width: thin;
}
.react-daterange-picker * {
  font-family: inherit;
}
.react-daterange-picker--closed {
  border-radius: 50px;
}
.react-daterange-picker--closed .react-daterange-picker__wrapper {
  border-radius: 50px;
}
.react-daterange-picker__wrapper {
  background: #ffffff;
  border: 0;
  border-radius: 50px;
  padding: 5px;
  font-size: 1em;
  border: 1px solid #ededed;
}
.react-daterange-picker__inputGroup {
  min-width: auto;
  flex-grow: 1;
  display: flex;
  padding: 2px;
  align-items: baseline;
}
.react-daterange-picker__inputGroup__divider {
  padding: 1px;
}
.react-daterange-picker__inputGroup__input {
  -moz-appearance: textfield;
}
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-daterange-picker__calendar {
  border-radius: 50px;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  border-top-left-radius: 0;
}
.react-daterange-picker__calendar .react-calendar {
  border-top-left-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: none;
}
.react-daterange-picker__clock {
  border-radius: 50px;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  font-family: inherit;
  width: 220px;
  height: 220px;
  padding: 20px;
  border: 0;
  border-top-left-radius: 0;
}
.react-daterange-picker__clock .react-clock__second-hand {
  transition: transform cubic-bezier(0.68, 0, 0.27, 1.55) 0.2s;
}
.react-daterange-picker__clock .react-clock__second-hand__body:before {
  width: 5px;
  height: 5px;
}
.react-daterange-picker__clock .react-clock__second-hand__body:after {
  width: 15px;
  height: 15px;
}
.react-date-picker__button {
  padding: 0 6px;
}
.react-date-picker__wrapper {
  border: none;
}
.react-date-picker__inputGroup__input:invalid {
  background:transparent;
}
.business-table .table tbody td {padding-top: 20px;padding-bottom: 20px;}
.dropdown-menu {
  background-color: #fff;
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  border-color: #ededed;
  border-radius: 10px;
  position: relative;
}
.dropdown-item {
  color: #4b5360;
  font-size: 14px;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #4b5360;
  background-color: #fbfbfb;
}
.react-daterange-picker__button {
  font-size: 16px;
  color: #9A9A9A;
}
.react-daterange-picker__wrapper {
  font-size: 16px;
}
.react-daterange-picker__calendar {
  min-width: 320px;
}
.is-sticky .appraisal-head-wrap.edit-page {
  position: fixed;
  top: 68px;
  left: 210px;
  right: 0;
  padding: 10px 32px;
  z-index: 7;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
  margin: 0;
  transition: all 0.4s ease-in-out;
}
.is-sticky .appraisal-head-wrap.edit-page .page-title {
  font-size: 22px;
}
.faq-table .table tbody td,.business-table .table tbody td{white-space: normal;}
.dashboard-sidebar-logo{padding: 20px;position: relative;}
.dashboard-sidebar-close-btn{position: absolute;top: 0;right: 0;height: 30px;width: 30px;display: flex;align-items: center;justify-content: center;background-color: #091747;border: none;outline: none;color: #fff;}
.vehicle-photo-wrap{cursor: pointer;}
.faqMian iframe {border-radius: 14px !important;width: 100% !important;}

.header {padding: 16px 85px;border-bottom: 1px solid #E5EBF3;}
.header .btn {padding: 20px 35px 17px;}
.home-banner-buttons .btn {padding: 26px 37px 23px;}
.header-logo img {height: 44px;}
.header-links {padding-right: 100px;}
.header-links ul {display: flex;align-items: center;}
.header-links ul li {margin-left: 32px;font-family: 'Roboto', sans-serif;font-weight: 500;font-size: 16px;line-height: 1.18;color: #4B5360;cursor: pointer;transition: all 0.4s ease-in-out;}
.header-links ul li:hover {color: #091747;}
.home-banner-section{padding-left: 85px;padding-right: 85px;padding-top: 156px;padding-bottom: 30px;background-color: #F6FBFF;background-image: url(../img/shape.svg);background-repeat: no-repeat;background-size: 100%;background-position: 100% 102%;}
.sec-subtitle {font-family: 'montbook';font-weight: 500;font-size: 26px;line-height: 1.4;color: #4B5360;margin-bottom: 16px;display: block;}
.sec-title {font-family: 'montbold';font-weight: 800;font-size: 58px;line-height: 1.3;color: #232931;display: block;margin-bottom: 32px;}
.home-steps {padding-top: 100px;}
.home-steps-con .sec-subtitle {display: block;font-family: 'montbook';font-weight: 500;font-size: 48px;line-height: 1.29;color: #091747;margin-bottom: 8px;}
.home-steps-con .sec-title {font-family: 'montbold';font-weight: 800;font-size: 48px;line-height: 1.3;color: #212121;display: block;margin-bottom: 16px;}
.home-steps-con p {font-family: 'Roboto', sans-serif;font-weight: 400;font-size: 24px;line-height: 1.16;color: #4B5360;}
.home-steps-con {max-width: 78%;}
.home-step {padding-bottom: 100px;}
.home-step.step-2 .home-steps-image{margin-right: -100px;}
.home-get-started {padding-left: 100px;padding-right: 100px;}
.home-get-started-inner {background-image: url(../img/get-started.jpg);background-repeat: no-repeat;background-size: cover;border-radius: 24px;padding-top: 85px;padding-bottom: 85px;}
.home-get-started-title {font-family: 'montbold';font-weight: 800;font-size: 48px;line-height: 1.64;color: #FFFFFF;display: block;margin-bottom: 42px;}
.home-get-started-tie {display: inline-flex;width: auto; align-items: center;padding: 16px 16px 16px 33px;background: linear-gradient(90deg, rgba(123, 178, 99, 0.6) 50%, rgba(55, 88, 114, 0.294) 81.04%);backdrop-filter: blur(15px);border: 1px solid rgba(45, 65, 102, 0.36);border-radius: 90px;}
.home-get-started-tie-text {font-family: 'montsemibold';font-weight: 600;font-size: 34px;line-height: 1.31;color: #FFFFFF;display: inline-block;margin-right: 87px;margin-bottom: 0;}
.home-get-started-call-us-sec{display: inline-flex;width: 40%;}
.home-get-started-call-us-sec span{font-family: 'montbold'; font-size: 32px;font-weight: 800;}
.home-get-started-call-us-sec h4{font-family: 'montbold';font-size: 22px;font-weight: 600;text-transform: capitalize;}
.home-get-started-button {font-weight: 600;font-size: 24px;line-height: 1.20;text-align: center;color: #5D8D6C;background-color: rgba(255, 255, 255, 0.9);border-radius: 150px;padding: 24px 40px;border: none;outline: none;transition: all 0.4s ease-in-out;}
.home-get-started-button:hover {color: #fff;background: linear-gradient(90deg, #6BB882 0%, #51A87B 100%);}
.footer {padding: 46px 0;}
.footer-con {display: flex;align-items: center;justify-content: center;}
.footer-con li {display: inline-block;margin-left: 32px;}
.footer-con li a {font-weight: 500;font-size: 16px;line-height: 1.18;text-decoration-line: underline;color: #2C3237;}
.footer-con span {font-weight: 500;font-size: 16px;line-height: 1.18;color: #5F666D;display: inline-block;}
.cmsWrap{padding: 80px 0;}

.login-copyright a,.footer-con a{color: #5F666D;}
.login-copyright a:hover,.footer-con a:hover{color: #091747;}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {background-color: #091747;border-color: #091747;}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after{background-color: #091747;}
.ant-tree-checkbox-checked::after,.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner, .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner{border-color: #091747;}
.ant-switch-checked{background-color: #091747;}
.ant-switch-checked:focus {box-shadow: 0 0 0 2px #091747;}
/* .notification-permissions */

/* subscription */
.subscription-monthly-price{font-size: 18px;font-weight: 700;color: #212121;line-height: 1;margin-bottom: 10px;display: block;}
.subscription-current-month{font-size: 16px;font-weight: 700;color: #212121;display: inline-block;}
.subscription-current-month span{margin-left: 10px;}
.subscription-ending-after{font-size: 16px;background-color: #fff2f2;padding: 8px 15px;border-radius: 50px;display: inline-flex;align-items: center;color: #091747;}
.subscription-ending-after i{font-size: 20px;margin-right: 7px;}
.subscription-ending-after b{margin-left: 5px;color: #091747;}
.billing-support-text{font-size: 14px;color: #000;font-weight: 500;display: inline-block;margin-top: 5px;}
.billing-support-text button{color: #091747;font-weight: 600;background-color: transparent;border: none;outline: none;transition: all 0.4s ease-in-out;}
.billing-support-text button:hover,.billing-support-text button:focus{color: #091747;}
.form-check-label {font-weight: 500;color: #747B87;font-size: 16px;user-select: none;cursor: pointer;}
.form-check-input:checked {background-color: #59C176;border-color: #59C176;}
.form-check-input:focus {border-color: #59C176;box-shadow: none;}
.form-check {padding-left: 30px;}
.form-check .form-check-input {margin-left: -30px;}
.form-check-input {width: 20px;height: 20px;margin-top: 2px;border-width: 1px;border-color: #e6e6e6;}
/* payment */
.payment-business-email{color: #78818d;font-size: 15px;font-weight: 400;display: block;margin-top: 3px;}
.payment-business-subscription-month{font-size: 16px;font-weight: 700;color: #212121;line-height: 1;display: inline-block;margin-right: 10px;}
.payment-business-subscription-exipre-after {line-height: 1;display: block;margin-top: 10px;font-size: 16px;color: #78818d;}
.dashboard-dealer-details-label{font-weight: 600;font-size: 18px;line-height: 1.1;color: #4b5360;display: block;margin-bottom: 7px;color: #212121;word-break: break-word;}
.dashboard-dealer-details-text{margin-bottom: 20px;display: block;line-height: 1.1;font-size: 18px;color: #3c3c3c;word-break: break-word;}
.icon-edit{font-size: 87%;}
.purchase-detail-text{font-weight: 600;display: block;}
.purchase-detail .form-label {color: #73787C;margin-bottom: 0;}
tr.is-expanded{border: none;}
tr.is-expanded td{background-color: #f7f7f7;border-radius: 10px;}
.expanded-btn {background-color: transparent;padding: 4px 8px;border: none;outline: none;transition: all 0.2s ease-in-out;}
.expanded-btn.active {transform: rotate(180deg);}
.bg-image-none{background-image: none !important;cursor: default !important;}
.appraisal-form-estimated-cost{margin-bottom: 20px;}
.appraisal-form-estimated-cost .form-select{box-shadow: none;border-radius: 50px;font-weight: 600;text-overflow: ellipsis;border-color: #e6e6e6;}
.ant-picker{width: 100%;border-radius: 50px;padding: 13px !important;color: #212121;background-color: transparent;border: 1px solid #dbdbdb;}

.highest-appraisal-count-header {margin-bottom: 15px;}
.highest-appraisal-count-header img {width: 45px;}
.highest-appraisal-count-header h6 {font-size: 18px;margin-left: 15px;margin-bottom: 0;}
.highest-appraisal-count-header h6 b {color: #66bf7a;}
.highest-appraisal-count h4{margin-bottom: 0;font-size: 18px;font-weight: 700;color: #eb3c77;}
.biggest-deal-month {border-top: 1px solid #e2e2e2;margin-top: 15px;padding-top: 15px;}
.highest-appraisal-count .btn{color: #00acee;font-weight: 600;position: relative;font-size: 14px;padding: 0;text-decoration: none;margin-right: 15px;}
.highest-appraisal-count .btn::after {content: "";width: 8px;height: 8px;border-bottom: 2px solid currentColor;border-right: 2px solid currentColor;position: absolute;right: -12px;transform: rotate(-45deg);margin: auto;top: 0;bottom: 0;}
.highest-appraisal-count .btn:hover{opacity: 0.8;}
.biggest-deal-month p{font-size: 14px;}
.dashboard-need-vehicle-list{max-height: 130px;overflow: auto;height: 100%;}
.dashboard-need-vehicle-list li{font-size: 15px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #e2e2e2;}
.dashboard-need-vehicle-list li:last-child{border-bottom: none;}

.scanner-wrap {position: fixed;top: 0;left: 0;z-index: 9999;width: 100%;height:100%;transform: translateY(-100%);background-color: #fff;display: flex;align-items: center;justify-content: center;transition: all 0.4s ease-in-out;}
.scanner-wrap.visible {transform: translateY(0);}
.scanner-open-btn {transform: translateY(-50%);right: -20px;width: 50px;height: 50px;font-size: 24px;}
.scanner-close-btn {width: 40px;height: 40px;top: 20px;right: 20px;background-color: #ff3d00;fill: #fff;padding: 9px;z-index: 999;transition: all 0.4s ease-in-out;}
.scanner-close-btn svg{width: 100%;height: 100%;}
.scanner-close-btn:hover{opacity: 0.8;}
.scanner-video {height: 100vh;width: 100%;}
.confirm-popup.active {opacity: 1;visibility: visible;}
.popup-close-btn {position: absolute;top: 10px;right: 10px;background-color: transparent;border: none;}
.popup-close-btn svg {fill: #fff;height: 25px;}
.confirm-popup {position: fixed;z-index: 9999;top: 50%;left: 50%;transform: translate(-50%, -50%);opacity: 0;visibility: hidden;transition: opacity 0.4s ease-in-out;}
.confirm-popup-inr {padding: 24px;width: 450px;background-color: #2c2c2c;border-radius: 12px;position: relative;}
.confirm-popup-con {max-width: 80%;margin-bottom: 15px;}
.confirm-popup-btns {display: flex;flex-wrap: wrap;justify-content: space-between;}
.confirm-popup-btns .btn {width: 48%;}

.contest-wrap .card-title{margin-bottom: 10px;}
.contest-wrap .card-title img{width: 35px;margin-right: 10px;}
.contest-timeline-text,.contest-item span{display: block;font-size: 14px;color: #5c6b6f;line-height: 1.2;margin-bottom: 5px;}
.contest-timeline-text i{font-size: 20px;margin-right: 10px;}
.contest-item h4{margin: 0;font-size: 24px;font-weight: 700;color: #4b5360;}

input.form-control[type="file"] {padding-left: 25px;}


.get-help-popup.send_main_popup .dashboard-popup-inner {
  max-width: 500px;
  padding: 46px 30px;
}

.send_popup {
  text-align: center;
}

.send_popup .get-help-Title {
    margin-bottom: 20px;
}

.qr_code {
  display: block;
  margin-top:25px;
}

.formCopyLinkWrap {
    align-items: center;
    background-color: #efefef;
    border-radius: 4px;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    margin-bottom: 15px;
    overflow: hidden;
    padding-left: 10px;
}

.formCopyLinkWrap span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.formCopyLinkWrap button {
    background-color: #00ac67;
    border-radius: 2px;
    color: #fff;
    outline: none;
    padding: 5px 11px;
    transition: all .4s ease-in-out;
    border:0;
}

.send_popup .btn {width: 100%;}
.mxw-150{max-width: 150px;}
.vehicle-details.without-title{margin-top: 48px;}
.mt-13{margin-top: 13px;}

.scan-frame-container {
  position: relative;
  max-width: 300px;
  margin: 0 auto;
  overflow: hidden;
}

.scan-frame-container:has(capture-photo[loading]) .scan-frame {
  display: none;
}

.scan-frame {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  pointer-events: none;
  outline: 200px solid #fff;
  border-radius: 5px;
}
capture-photo {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: #000000;
}

capture-photo::part(video) {
  width: 100%;
  height: calc(100% - 10px);
  border-radius: 5px;
  background-color: #000000;
}

capture-photo[loading]::part(video) {
  background-image: url(../img/spinner-light.svg);
  background-size: 60px;
  background-position: center center;
  background-repeat: no-repeat;
}

capture-photo::part(facing-mode-button) {
  display: none;
}
